<template>
  <div class="favq clearfix">
    <heand :tagnum="tagnum"></heand>
    <div class="enterprise clearfix">
      <div class="left">
        <ziyuannavleft />
      </div>
      <div class="right">
        <div class="soso_box">
          <h1 class="zhaosh_tit">招商项目管理</h1>
          <ul class="ipt">
            <li>
              行业：
              <el-select
                class="iipp"
                v-model="valuetype"
                placeholder="请选择"
                @change="labelchange($event, industry, 1)"
              >
                <el-option
                  v-for="(item, index) in industry"
                  :key="index"
                  :label="item.name"
                  :value="item.industry_id"
                ></el-option>
              </el-select>
            </li>
            <li>
              投资额：
              <el-select
                class="iipp"
                v-model="valueaptitude"
                placeholder="请选择"
                @change="labelchange($event, invest, 2)"
              >
                <el-option
                  v-for="(item, index) in invest"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </li>
            <li>
              项目进度：
              <el-select
                class="iipp"
                v-model="valuecapital"
                placeholder="请选择"
                @change="labelchange($event, stage, 3)"
              >
                <el-option
                  v-for="(item, index) in stage"
                  :key="index"
                  :label="item.name"
                  :value="item.stage_id"
                ></el-option>
              </el-select>
            </li>
            <li>
              报送部门：
              <el-select
                class="iipp"
                v-model="valuestate"
                placeholder="请选择"
                @change="labelchange($event, section, 4)"
              >
                <el-option
                  v-for="item in section"
                  :key="item.stage_id"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </li>
            <li>
              搜索：
              <el-input
                v-model="keyword"
                placeholder="搜索企业名称或项目产品名称"
              ></el-input>
            </li>
            <span class="seach" @click="querycondition">搜索</span>
          </ul>
        </div>

        <div class="project_main">
          <dl class="project_tit">
            <dt>项目列表</dt>
            <dd>
              <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
                >全选</el-checkbox
              >
              <span
                class="xzeng_btn"
                @click="$router.push({ name: 'projectNew' })"
                >+新增</span
              >
              <span class="del_btn" @click="alldelete">批量删除</span>
              <span class="xiaz_btn" @click="downManageItem()">批量下载</span>
            </dd>
          </dl>
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td style="width: 3%">
                  <el-checkbox
                    v-model="checkAll"
                    @change="handleCheckAllChange"
                  ></el-checkbox>
                </td>
                <td style="width: 3%">序号</td>
                <td style="width: 13%">企业名称</td>
                <td style="width: 15%">项目名称</td>
                <!-- <td style="width: 7%">投资产品</td> -->
                <td style="width: 7%">所在行业</td>
                <td style="width: 10%">投资金额</td>
                <td style="width: 7%">项目进度</td>
                <td style="width: 7%">报送部门</td>
                <td style="width: 7%">项目对接人</td>
                <td>操作</td>
                <td>状态</td>
              </tr>
              <tr v-for="(item, index) in listdata" :key="index">
                <td>
                  <el-checkbox-group
                    v-model="checkedCities"
                    @change="handleCheckedCitiesChange"
                  >
                    <el-checkbox :label="item.id"></el-checkbox>
                  </el-checkbox-group>
                </td>
                <td>{{ index + 1 }}</td>
                <td>{{ item.company_name }}</td>
                <td>{{ item.name }}</td>
                <!-- <td>{{ item.product }}</td> -->
                <td>{{ item.industry }}</td>
                <td>{{ item.invest }}亿元（人民币）</td>
                <td>{{ item.stage }}</td>
                <td>{{ item.section }}</td>
                <td>{{ item.link }}</td>
                <td>
                  <span
                    class="chengzi_btn"
                    @click="
                      $router.push({
                        name: 'projectgldetsils',
                        query: { id: item.id },
                      })
                    "
                    >查看</span
                  >
                  <span
                    class="lvzi_btn"
                    @click="
                      $router.push({
                        name: 'projectgldetsilsbianj',
                        query: { id: item.id },
                      })
                    "
                    >编辑</span
                  >
                  <el-popover
                    placement="bottom"
                    width="316"
                    trigger="click"
                    @show="(showorg = true), (itemid = item.id)"
                    :ref="'pop' + item.id"
                  >
                    <div v-if="showorg">
                      <p>选择机构</p>
                      <div class="dispatch">
                        <ul>
                          <li
                            v-for="(items, indexs) in dispatchorg"
                            :key="indexs"
                            :class="
                              government == items.government_id ? 'active' : ''
                            "
                            @click="getsection(items.government_id)"
                          >
                            {{ items.government_name }}
                          </li>
                        </ul>
                      </div>
                      <div class="btn">
                        <!-- <el-button id="but2">取消</el-button> -->
                        <el-button id="but1" @click="getSectionPeople()"
                          >确定</el-button
                        >
                      </div>
                    </div>
                    <div v-if="!showorg">
                      <p>选择部门</p>
                      <div class="dispatch branch">
                        <el-collapse accordion>
                          <el-collapse-item
                            v-for="(item, index) in branchdata"
                            :key="index"
                          >
                            <template slot="title">{{ item.name }}</template>
                            <ul>
                              <li
                                v-for="(itemx, indexx) in item.person"
                                :key="indexx"
                                :class="userid == itemx.id ? 'active' : ''"
                                @click="sendid(itemx.id)"
                              >
                                {{ itemx.name }}
                              </li>
                            </ul>
                          </el-collapse-item>
                        </el-collapse>
                      </div>
                      <div class="btn">
                        <el-button id="but1" @click="getcontrolItem(index)"
                          >确定</el-button
                        >
                      </div>
                    </div>
                    <el-button slot="reference" class="zizi_btn"
                      >调度</el-button
                    >
                  </el-popover>
                  <el-popover
                    placement="bottom"
                    width="316"
                    trigger="click"
                    :ref="'popgx' + item.id"
                  >
                    <p>选择人员</p>
                    <div class="dispatch branch">
                      <el-collapse accordion>
                        <el-collapse-item
                          v-for="(items, index) in Sharedata"
                          :key="index"
                        >
                          <template slot="title">{{ items.section }}</template>
                          <ul>
                            <li
                              v-for="(itemxg, indexx) in items.person"
                              :key="indexx"
                              :class="userid == itemxg.id ? 'active' : ''"
                              @click="sendid(itemxg.id)"
                            >
                              {{ itemxg.name }}
                            </li>
                          </ul>
                        </el-collapse-item>
                      </el-collapse>
                    </div>
                    <div class="btn">
                      <!-- <el-button id="but2">取消</el-button> -->
                      <el-button id="but1" @click="getshareItem(item.id)"
                        >确定</el-button
                      >
                    </div>
                    <el-button slot="reference" class="redzi_btn"
                      >共享</el-button
                    >
                  </el-popover>
                  <el-popover
                    placement="bottom"
                    width="400"
                    trigger="click"
                    :ref="'popsb' + item.id"
                  >
                    <p>选择机构</p>
                    <div class="dispatch">
                      <ul>
                        <li
                          v-for="(items, indexs) in dispatchorg"
                          :key="indexs"
                          :class="
                            government == items.government_id ? 'active' : ''
                          "
                          @click="getsection(items.government_id)"
                        >
                          {{ items.government_name }}
                        </li>
                      </ul>
                    </div>
                    <div class="btn">
                      <!-- <el-button id="but2">取消</el-button> -->
                      <el-button id="but1" @click="getupItem(item.id)"
                        >确定</el-button
                      >
                    </div>
                    <el-button slot="reference" class="bluezi_btn"
                      >上报</el-button
                    >
                  </el-popover>
                  <el-popover
                    placement="bottom"
                    width="300"
                    trigger="click"
                    :ref="'popsc' + item.id"
                  >
                    <p
                      style="
                        font-size: 16px;
                        font-weight: 700;
                        text-align: center;
                      "
                    >
                      确定删除此项目？
                    </p>
                    <div class="btn">
                      <el-button id="but2" @click="getclear()">取消</el-button>
                      <el-button id="but1" @click="getdelete(item.id, index)"
                        >确定</el-button
                      >
                    </div>
                    <el-button slot="reference" class="huangzi_btn"
                      >删除</el-button
                    >
                  </el-popover>
                  <!-- <span class="huangzi_btn">删除</span> -->
                </td>
                <td>{{ item.status }}</td>
              </tr>
            </table>
          </div>
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage1"
              :page-size="10"
              layout="total, prev, pager, next"
              :total="Number(total)"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  listAddItem,
  ManageItem,
  listGetUnit,
  getSectionPeople,
  getdispatch,
  getupItem,
  getdeleteReport,
  getSectionShare,
  getshareItem,
  downManageItem,
} from "@/api/index";
export default {
  name:"projectgl",
  data() {
    return {
      currentPage1: 1,
      valuetype: "",
      valuestate: "",
      valueaptitude: "",
      valuecapital: "",
      visible: false,
      input: "",
      tagnum: "7",
      checked: 1,
      checked1: 1,
      checkAll: false,
      checkAllarr: [],
      industry: [], // 能源
      invest: [], // 投资额
      stage: [], // 进度
      section: [], // 部门
      industryid: "", // 能源
      investid: "", // 投资额
      stageid: "", // 进度
      sectionid: "", // 部门
      keyword: "",
      page: 1,
      perpage: 10,
      order: 1,
      listdata: [],
      dispatchorg: [], //机构数据
      government: "", // 机构id
      showorg: true,
      branchdata: "",
      itemid: "",
      userid: "",
      total: "",
      Sharedata: [],
      checkedCities: [],
    };
  },
  created() {
    this.getlistAddItem();
    this.getManageItem();
    this.getlistGetUnit();
    this.getSectionShare();
  },
  methods: {
    //全选
    handleCheckAllChange(val) {
      var checkeall = [];
      for (var i = 0; i < this.listdata.length; i++) {
        checkeall.push(this.listdata[i].id);
      }
      this.isIndeterminate = false;
      this.checkedCities = val ? checkeall : [];
    },
    // 取消全选
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.listdata.length;
    },
    // 批量删除
    async alldelete() {
      const res = await getdeleteReport({ item_id: this.checkedCities });
      if (res.code == 0) {
        this.getManageItem();
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 批量下载
    async downManageItem() {
      const res = await downManageItem({
        item_id: this.checkedCities,
      });
      this.jqDownload(res.url, res.filename);
    },
    jqDownload(url, name) {
      fetch(url).then((res) =>
        res.blob().then((blob) => {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(blob);
          var filename = name;
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
        })
      );
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getManageItem();
    },
    async getlistAddItem() {
      const res = await listAddItem();
      this.industry = res.industry;
      this.invest = res.invest;
      this.stage = res.stage;
      this.section = res.section;
    },
    async getManageItem() {
      const res = await ManageItem({
        indu_id: this.industryid, //--“行业id”,
        invest_id: this.investid, //--投资额ID，
        section: this.sectionid, //--部门，
        search: this.keyword, //--搜索栏的值
        page: this.page, //-- 页数，
        perpage: this.perpage, // -- 每页个数，
        stage_id: this.stageid, //项目进展的ID，
        order: this.order, //--  投资额排序  1为顺序，2为倒序
      });
      this.$nextTick(function () {
        var text = document.getElementsByClassName("zizi_btn");
      });
      this.listdata = res.data_list;
      this.total = res.maxnum;
    },
    labelchange(e, objx, num) {
      switch (num) {
        case 1:
          this.industryid = e;
          break;
        case 2:
          this.investid = e;
          break;
        case 3:
          this.stageid = e;
          break;
        case 4:
          this.sectionid = e;
          break;
      }
    },
    querycondition() {
      this.getManageItem();
    },
    // 获取机构
    async getlistGetUnit() {
      const res = await listGetUnit();
      this.dispatchorg = res;
    },
    getsection(id) {
      this.government = id;
    },
    async getSectionPeople() {
      if (this.government == "") {
        this.$message({
          message: "请选择机构",
          type: "error",
        });
      }
      const res = await getSectionPeople({
        government_id: this.government,
      });
      this.branchdata = res;
      this.showorg = false;
    },
    sendid(id) {
      this.userid = "";
      this.userid = id;
    },
    async getcontrolItem(index) {
      const res = await getdispatch({
        item_id: this.itemid,
        user_id: this.userid,
      });

      this.listdata.splice(index, 1);
      let refName = "pop" + this.itemid;
      this.$refs[refName][0].doClose();
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 上报
    async getupItem(id) {
      if (this.government == "") {
        this.$message({
          message: "请选择上报机构",
          type: "error",
        });
        return false;
      }
      const res = await getupItem({
        item_id: id,
        unit_id: this.government,
      });
      let refName = "popsb" + id;
      this.$refs[refName][0].doClose();
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.getManageItem();
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 删除
    async getdelete(id, index) {
      var idx = [];
      idx.push(id);
      let refName = "popsc" + id;
      this.$refs[refName][0].doClose();
      const res = await getdeleteReport({ item_id: idx });
      if (res.code == 0) {
        this.getManageItem();
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 共享
    async getSectionShare() {
      const res = await getSectionShare();
      this.Sharedata = res;
    },
    async getshareItem(id) {
      if (this.userid == "") {
        this.$message({
          message: "请选择人员",
          type: "error",
        });
        return false;
      }
      let refName = "popgx" + id;
      this.$refs[refName][0].doClose();
      const res = await getshareItem({
        user_id: this.userid, //需要分享的人的ID
        item_id: id, // 项目id
      });
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.favq {
  width: 100%;
  min-height: 100%;
  background: rgb(243, 243, 243);
  padding-bottom: 30px;

  li {
    list-style: none;
  }

  .c-top {
    width: 1920px;
    height: 72px;
    background: #fff;

    li {
      display: block;
      width: 100px;
      height: 38px;
      background: #eee;
      margin-top: 19px;
      border-radius: 6px;
      margin-right: 19px;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      text-align: center;
      line-height: 38px;
      float: left;
      &:nth-child(1) {
        margin-left: 52px;
      }
      &.active {
        background: rgb(254, 147, 34);
        color: #ffffff;
      }
    }
  }
}
.enterprise {
  padding: 0 30px;
}
.left {
  float: left;
  background: #fff;
  min-width: 170px;

  .dxhj {
    padding-top: 13px;
    width: 100%;
    height: 38px;

    img {
      float: left;
      width: 38px;
      height: 38px;
      margin-left: 13px;
    }

    span {
      float: left;
      line-height: 31px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
    }
  }

  /deep/ .el-collapse-item__header {
    height: 40px;
    padding-left: 40px;
    font-size: 14px;
  }

  /deep/ .el-collapse-item__header.is-active {
    color: #fe6a00;
  }
}
.seach {
  display: inline-block;
  width: 54px;
  height: 30px;
  background: #fe6a00;
  border-radius: 4px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  margin: 1% 0;
  cursor: pointer;
}
.right {
  width: calc(100% - 256px - 15px);
  min-height: 89vh;
  float: right;
  margin-left: 10px;

  .soso_box {
    background: #fff;
    padding: 22px 10px 10px 22px;
    margin-bottom: 15px;
    overflow: hidden;
    zoom: 1;

    .zhaosh_tit {
      font-size: 18px;
      font-weight: bold;
      text-align: left;
    }

    .ipt {
      overflow: hidden;
      zoom: 1;
      text-align: left;
      li {
        display: block;
        width: 16.5%;
        height: 31px;
        float: left;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #333333;
        margin: 1% 0;

        /deep/.el-select {
          width: 70%;
          height: 30px;
          input {
            width: 100%;
            height: 30px;
            background: #ffffff;
            border: 1px solid #dddddd;
          }
          /deep/.el-input__icon {
            line-height: 30px !important;
          }
        }
        /deep/.el-input {
          width: 100%;
          height: 30px;
          input {
            width: 100%;
            height: 30px;
            color: #333;
            background: #ffffff;
            border: 1px solid #dddddd;
          }
          ::-webkit-input-placeholder {
            color: #333;
          }
          /deep/.el-icon-circle-close {
            background: url("../../assets/merchantsradar/cuo.png") 50% 50%
              no-repeat;
          }
          .el-icon-circle-close:before {
            content: "";
          }
        }
      }

      li:nth-child(2) {
        width: 17.5%;
        /deep/.el-select {
          width: 60%;
        }
      }
      li:nth-child(3) {
        width: 17.5%;
        /deep/.el-select {
          width: 55%;
        }
      }
      li:nth-child(4) {
        width: 26%;
        /deep/.el-select {
          width: 70%;
        }
      }
      li:nth-child(5) {
        width: 17%;
        /deep/.el-input {
          width: 70%;
        }
      }
    }
  }

  .project_main {
    background: #fff;
    padding: 18px 10px 10px 22px;
    min-height: 80%;
    overflow: hidden;
    zoom: 1;

    .project_tit {
      overflow: hidden;
      zoom: 1;
      margin-bottom: 10px;

      dt {
        float: left;
        font-weight: bold;
        font-size: 16px;
        color: #fe6a00;
        height: 30px;
        line-height: 30px;
      }

      dt:before {
        content: " ";
        padding: 0 3px 0 0;
        margin-right: 6px;
        background: #fe6a00;
      }

      dd {
        float: right;

        .el-checkbox {
          margin-right: 15px;
        }

        .xzeng_btn {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          background: #fe6a00;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;
          color: #ffffff;
          margin: 1% 5px;
          padding: 0 20px;
          cursor: pointer;
        }

        .del_btn {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          border: 1px solid #fe6a00;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;
          color: #fe6a00;
          margin: 1% 5px;
          padding: 0 20px;
          cursor: pointer;
        }

        .xiaz_btn {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          border: 1px solid #fe6a00;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;
          color: #fe6a00;
          margin: 1% 5px;
          padding: 0 20px;
          cursor: pointer;
        }
      }
    }

    .project_tables {
      font-size: 14px;
      line-height: 44px;

      tr:nth-child(odd) {
        background: #f3f6fb;

        td {
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
        }
      }
      span {
        margin: 0 2%;
      }
      .chengzi_btn {
        color: #fe6a00;
        cursor: pointer;
      }
      .lvzi_btn {
        color: #2cb54c;
        cursor: pointer;
      }
      .zizi_btn {
        color: #135de6;
        cursor: pointer;
      }
      .redzi_btn {
        color: #e51313;
        cursor: pointer;
      }
      .bluezi_btn {
        color: #0ebbf3;
        cursor: pointer;
      }
      .huangzi_btn {
        color: #e69554;
        cursor: pointer;
      }

      /deep/.el-button {
        background: none;
        padding: 0;
        border: 0;
      }
    }

    //分页
    /deep/.el-pagination {
      float: right;
      margin-top: 20px;
    }
    /deep/.btn-prev {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.btn-next {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.el-pager li {
      border: 1px solid #888;
      margin: 0 5px;
    }
    /deep/.el-pager li.active,
    /deep/.el-pager li:hover {
      border: 1px solid #fe6a00;
      color: #fe6a00;
    }

    //分页end
  }
}
.dispatch {
  width: 100%;
  height: 138px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  z-index: 99;
  margin-top: 15px;
  ul {
    width: 100%;
    height: 138px;
    padding: 10px;
    overflow: auto;
    li {
      list-style: none;
      cursor: pointer;
      height: 30px;
      line-height: 20px;
      width: 100%;
      padding: 5px;
      &:hover {
        background-color: #eee;
        color: #e69554;
      }
      &.active {
        color: #e69554;
        background-color: #eee;
      }
    }
  }
}
.btn {
  text-align: center;
  margin-top: 10px;
  #but1 {
    width: 72px;
    height: 30px;
    background: #fe6a00;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    padding: 0;
  }
  #but2 {
    width: 72px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #fe6a00;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #fe6a00;
    line-height: 30px;
    padding: 0;
  }
}
.branch {
  overflow: auto;
  ul {
    height: auto;
  }
}
.el-collapse-item {
  padding: 0 10px;
}
.project_tables /deep/ .el-checkbox__label {
  display: none;
}
</style>